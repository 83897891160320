import React from 'react'
import {Link} from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CoolHeader, {SectionHeader} from '../components/Elements/CoolHeader'
import {TinyLetterForm, CallForEntriesLink} from '../components/Subscribe'

const Submit = () => (
  <Layout>
    <SEO customTitle="Submit Work to Pomme Journal" />
    <CoolHeader>OPEN CALL FOR SUBMISSIONS</CoolHeader>
    <p>
      <b>"Put Into Words, My Love"</b>
    </p>
    <p>
      Pomme Journal is pleased to announce a call for submissions for their
      inaugural <b>Petite Pomme</b>, a miniature sized journal at 4x6", entitled
      <i>"Put Into Words, My Love"</i>
    </p>
    <p>
      <CallForEntriesLink
        disabled={false}
        target="_blank"
        href="https://pommejournal.submittable.com/submit/150326/put-into-words-my-love-petite-pomme-by-pomme-journal"
      >
        Click here to SUBMIT
      </CallForEntriesLink>
    </p>
    <br />
    <p>
      <b>The Details</b>
    </p>
    <p>
      We're looking forward to falling in love with your work! "Put Into Words,
      My Love" is an exploration of the feeling of love, and an attempt to put
      it into words. The final collection will be a long love story, small
      enough to fit in your pocket and suitable for recitation to your dearest
      loved one.
    </p>
    <p>
      <b>Accepted work will feel </b>
      <ul>
        <li>genuine</li>
        <li>gender neutral</li>
        <li>reflective</li>
        <li>uplifting</li>
      </ul>
    </p>

    <p>
      Specifications Please note, submissions that do not meet the guidelines
      will not be accepted.
    </p>
    <p>
      <b>All submissions must be </b>
      <ul>
        <li>
          no longer than 900 CHARACTERS (please note, spaces and punctuation
          count as a character).
        </li>
        <li>
          OR 20 lines (Take into consideration, a full return space between
          stanzas counts as a line).
        </li>
      </ul>
      Accepted work will be displayed in 9pt font in a space approximately
      2.5x5".
    </p>
    <p>
      <b>Feel free to submit up to two works per entry!</b>
    </p>
    <p>
      <b>IMPORTANT, PLEASE READ:</b> We will not accept erotica or work that may
      be deemed lewd or overly sensual. If you have questions prior to
      submission, please direct them to pommejournalcomm@gmail.com. We thank you
      for your respect.
    </p>
    <CoolHeader>Recently Closed</CoolHeader>
    <p>
      <b>"Fernweh"</b>
    </p>
    <p>
      <b>Fall 2019 submission period for Fernweh is now closed.</b>
    </p>
    <p>
      <i>Fernweh</i> is a German word used to describe a feeling of wanderlust,
      farsickness or a deep longing to be somewhere else. Pomme is looking to
      create a publication of fictional short stories and poems that feature
      characters with this sense of longing or incite farsickness in the reader.
    </p>

    <p>
      Check back soon for more information on Fernweh's upcoming publication!
    </p>

    <p>
      <CallForEntriesLink
        disabled={true}
        target="_blank"
        href="https://pommejournal.submittable.com/submit/136762/call-for-entries-pomme-journal-debut-issue-fernweh"
      >
        Click here to SUBMIT
      </CallForEntriesLink>
    </p>
    {/* <br />

    <p>
      Our team is small and dedicated. Due to limited resources, we can only
      accept a limited number of submissions each month. Please sign up for our
      newsletter to be notified when Pomme is accepting submissions.
    </p>
    <p>
      Submit your work via Submittable. In order to give more writers the
      opportunity to submit, please limit your submissions to 2 per category.
    </p>
    <p>
      Pomme will respond in a timely fashion. We will select a featured short
      story and a featured poem. We will also select a few additional works in
      each category for publication.
    </p>
    <p>The Featured Short Story and Featured Poem will receive:</p>

    <ul>
      <li>A Pro spread in Pomme Journal</li>
      <li>A free copy of the journal</li>
      <li>A Pomme Journal T-Shirt</li>
      <li>A $25 gift card</li>
    </ul> */}
  </Layout>
)

export default Submit
